.nav-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightblue;
    height: 50px;
    margin-bottom: -16px;
}

.nav-item {
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: none;
    color: black;
}
/* html, body {
    height: 100%;
    background-image: url("../../../public/images/background.jpg");
    background-repeat: repeat-y;
    background-size: cover;
} */

html, body {
    height: 100%;
}

.background-projects {
    margin-top: 16px;
    position: relative;
    z-index: 1;
}

.background-projects::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 50px);
    opacity: 0.95;
    z-index: -1;
    background-image: url("../../images/background.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    filter: brightness(35%);
}

.background-projects h1 {
    color: #cce6ff;
}

.projects-cnt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.project {
    margin-left: 30px;
    margin-right: 30px;
    width: 400px;
    border: 2px solid black;
    border-radius: 20px;
    background-color: #cce6ff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project img {
    width: 300px;
    height: 200px;
}

.project p {
    margin-left: 20px;
    margin-right: 20px;
}

.project span {
    margin-top: auto;
}

.project button {
    margin: auto 5px 15px 5px;
    padding: 10px;
    color: white;
    background-color: #3366ff;
    border-color: #0000ff;
    border-radius: 5px;
}

.project button:hover {
    cursor: pointer;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.4);
}
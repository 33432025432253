/* html, body {
    height: 100%;
    background-image: url("../../../public/images/background.jpg");
    background-repeat: repeat-y;
    background-size: cover;
} */

html, body {
    height:100%;
}

.background-about {
    margin-top: 16px;
    position: relative;
    z-index: 1;    
}

.background-about::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.95;
    z-index: -1;
    background-image: url("../../images/background.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    filter: brightness(35%);
}

.about-cnt {
    width: 900px;
    max-height: 850px;
    margin-left: 25vw;
    border: 2px solid black;
    border-radius: 20px;
    background-color: #cce6ff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.photo-cnt {
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    padding: 30px 30px 0px 30px;
    display: flex;
    /* flex-direction: column; */
}

.photo-cnt img{
    /* justify-content: center; */
    width: 400px;
    max-height: 400px;
    width: auto;
    height: auto;
}

.about-me {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    padding-top: -10px;
}

.about-me p {
    padding-right: 25px;
    line-height: 1.5;
}

.about-me img {
    max-width: 50px;
    max-height: 50px;
    width: auto;
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
}

.about-skills {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: span 1;
}

.about-skills p {
    line-height: 0.8;
}
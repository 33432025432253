.contact-cnt {
    width: 350px;
    min-height: 480px;
    border: 2px solid black;
    border-radius: 20px;
    margin-left: calc(50vw - 175px);
    background-color: #cce6ff;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact-form p {
    margin: 10px 35px 5px 35px;
}

.contact-field {
    width: 280px;
    height: 30px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-left: 35px;
}

.contact-textarea {
    width: 280px;
    height: 150px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-left: 35px;
    resize: vertical;
}

.contact-submit {
    margin: 10px auto 10px 35px;
    padding: 10px;
    color: white;
    background-color: #3366ff;
    border-color: #0000ff;
    border-radius: 10px;
}

.contact-submit:hover {
    cursor: pointer;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.4);
}